import {MdOutlineFilterList} from "react-icons/md";
import {useEffect, useState} from "react";
import FilterModal from "@/components/modals/filterModal.tsx";
import {getGroupPTs} from "@/apis/api.tsx";
import {IPT} from "@/types/type.ts";
import * as Sentry from "@sentry/react";

interface SessionFilterProps {
    applyFilter: (ptId: number) => void,
    groupId: number,
}

export const SessionFilter = ({applyFilter, groupId}: SessionFilterProps) => {
    const [openFilter, setOpenFilter] = useState<boolean>(false)
    const [groupPTs, setGroupPTs] = useState<IPT[]>([]);
    const [fetching, setFetching] = useState<boolean>(false);
    const [pt, setPt] = useState<IPT | null>(null);

    useEffect(() => {
        if (fetching) return;
        setFetching(true);
        getGroupPTs(groupId)
            .then((data: IPT[]) => setGroupPTs(data))
            .catch((error) => {
                Sentry.captureException(error);
                console.error(error);
            })
            .finally(() => setFetching(false));
    }, [groupId]);

    const handleApplyFilter = (ptId: number) => {
        const pt = groupPTs.find(pt => pt.id === ptId);
        setPt(pt ?? null);
        setOpenFilter(false);
        applyFilter(pt?.id ?? 0);
    }

    return <>
        <div className={`flex justify-between`}>
            {pt &&
                <div>
                    <div className={'px-2 bg-[#ec6730] rounded text-sm text-white text-left'}>{pt.first_name} {pt.last_name}&nbsp;<button onClick={() => handleApplyFilter(0)}>&times;</button></div>
                </div>
            }
            {!pt && <div>&nbsp;</div>}
            <div>

                <button onClick={() => setOpenFilter(true)}
                        className={`text-center p-2 bg-[#eeeeee] border-[#a6a6a6] border rounded-full`}>
                    <MdOutlineFilterList className={`w-4 h-4 text-[#2e8889]`}/>
                </button>
            </div>
        </div>
        {openFilter && <FilterModal
            closeModal={() => setOpenFilter(false)}
            onApply={handleApplyFilter}
            groupPTs={groupPTs}
            ptId={pt?.id ?? 0}
        />}
    </>
}
