import * as Sentry from "@sentry/react";
import {useEffect, useState} from "react";
import {getAccessToken} from "@/apis/api.tsx";
import {AccessTokenResponse} from "@/types/type.ts";

interface AuthWrapperProps {
    children: React.ReactNode;
}

export const AuthWrapper = ({children}: AuthWrapperProps) => {
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState<string | null>(localStorage.getItem('AccessToken') ?? null);
    useEffect(() => {
        if (token) {
            console.log('Token already exists');
            setLoading(false);
            return;
        }
        if (!token) {
            console.log('Getting access token');
            setLoading(true);
            getAccessToken()
                .then((result: AccessTokenResponse) => {
                    localStorage.setItem('AccessToken', result.data.access_token);
                    setToken(result.data.access_token);
                })
                .catch((error) => {
                    Sentry.captureException(error);
                    console.error(error);
                })
                .finally(() => setLoading(false));
        }
    }, [loading, token]);
    if (token) {
        return <>{children}</>;
    }
    return <></>
}
