import {ErrorMessage, Field, Form, Formik} from "formik";
import React from "react";
import * as Yup from "yup";
import {login, register} from "@/apis/api.tsx";
import {scrollTop} from "@/utils/goToTop.ts";
import {FaSpinner} from "react-icons/fa6";
import mixpanel from "mixpanel-browser";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {faSms} from "@fortawesome/free-solid-svg-icons";
import {IUser} from "@/types/type.ts";
import {Header} from "@/components/commons/Header.tsx";
import * as Sentry from "@sentry/react";

interface Props {
    nextStep: (user: IUser) => void;
    campaignData?: { campaignSource: string | null; campaignName: string | null; campaignMedium: string | null }
    username?: string
}

const Person: React.FC<Props> = ({nextStep, campaignData, username}) => {
    return (
        <>
            <Header title={"And we just need a few more personal details..."} subtitle={"These allow us to send you reminders and notifications about the sessions"}/>
            <div className={'flex-1'}>
                <Formik
                    enableReinitialize={false}
                    initialValues={{
                        first_name: "",
                        last_name: "",
                        // sex: "",
                        // password: "",
                        email: username ?? "",
                        mobile: "",
                        // body_weight: 0,
                        // unit_preference: "",
                        // lifting_experience_id: 0,
                        whatsapp_comms: true,
                        sms_comms: true,
                        campaign_source: campaignData?.campaignSource,
                        campaign_medium: campaignData?.campaignMedium,
                        campaign_name: campaignData?.campaignName
                    }}
                    onSubmit={(values, {setFieldError, setSubmitting}) => {
                        setSubmitting(true);
                        const password = Math.random().toString(36).slice(-8);
                        const params = {
                            ...values,
                            email_confirmation: values.email,
                            password: password,
                            password_confirmation: password,
                            // unit_preference: values.unit_preference.length ? values.unit_preference:  'kg',
                            // body_weight: values.body_weight > 0 ? values.body_weight : 0,
                            // lifting_experience_id: values.lifting_experience_id ? values.lifting_experience_id : 1,
                            whatsapp_comms: values.whatsapp_comms,
                            sms_comms: values.sms_comms
                        }

                        register(params).then(response => {
                            login({
                                email: params.email,
                                password: params.password
                            }).then(() => {
                                const user = response.data.data;
                                mixpanel.identify(user.id.toString());
                                mixpanel.people.set({
                                    '$name': user.first_name + " " + user.last_name,
                                    '$email': user.email,
                                    'userType': 'user'
                                });
                                nextStep(user);
                            }).catch((error) => {
                                Sentry.captureException(error);
                                const messages = error?.response?.data?.errors;
                                if (Object.keys(messages).length > 0) {
                                    Object.keys(messages).forEach((key) => {
                                        alert(messages[key][0])
                                    });
                                }
                                setSubmitting(false)
                            });
                        }).catch((error) => {
                            Sentry.captureException(error);
                            if (error?.response?.status === 422) {
                                const messages = error?.response?.data?.errors;
                                if (Object.keys(messages).length > 0) {
                                    Object.keys(messages).forEach((key) => {
                                        setFieldError(key, messages[key][0]);
                                    });
                                }
                                scrollTop()
                            }
                            setSubmitting(false)
                        })
                    }}
                    validationSchema={Yup.object().shape({
                        first_name: Yup.string().required("Please enter first name"),
                        last_name: Yup.string().required("Please enter last name"),
                        email: Yup.string()
                            .required("Please enter email address")
                            .email("Please enter a valid email address"),
                        // password: Yup.string()
                        //     .required("Please enter password")
                        //     .min(8, "The password must be at least 8 characters."),
                        mobile: Yup.string().required("Please enter phone number"),
                        // body_weight: Yup.string().required("Please enter body weight"),
                        whatsapp_comms: Yup.boolean(),
                        sms_comms: Yup.boolean(),
                    })}
                >
                    {({isSubmitting, errors}) => (
                        <Form>
                            <div>
                                <p className="text-base font-bold">Name</p>
                                <Field
                                    type="text"
                                    name="first_name"
                                    placeholder="First name"
                                    required
                                    autoComplete={"given-name"}
                                    className={`rounded w-full p-4 border text-xs mt-2 focus:outline-0
                                        bg-white text-gray-900 placeholder:text-gray-100 ${errors?.first_name ? 'border-[#FF0002]' : ''}`}
                                />
                                <ErrorMessage name="first_name">
                                    {(msg) => (
                                        <span className="text-sm text-[#FF0002]">
                                                {msg}
                                            </span>
                                    )}
                                </ErrorMessage>

                                <Field
                                    type="text"
                                    name="last_name"
                                    placeholder="Last name"
                                    required
                                    autoComplete={"family-name"}
                                    className={`rounded w-full p-4 border text-xs mt-2 focus:outline-0
                                        bg-white text-gray-900 placeholder:text-gray-100 ${errors?.last_name ? 'border-[#FF0002]' : ''}`}
                                />
                                <ErrorMessage name="last_name">
                                    {(msg) => (
                                        <span className="text-sm text-[#FF0002]">
                                                {msg}
                                            </span>
                                    )}
                                </ErrorMessage>
                            </div>
                            <div className={`mt-8`}>
                                <p className="text-base font-bold">Contact details</p>
                                <p>Please tick where you'd like to receive your order confirmation and session reminders</p>
                                <label>
                                    <Field type="checkbox" name="whatsapp_comms" className={`rounded mx-3 p-4 border text-xs mt-2 focus:outline-0
                                        bg-white text-gray-900 placeholder:text-gray-100 ${errors?.whatsapp_comms ? 'border-[#FF0002]' : ''}`}/>
                                    Send to <FontAwesomeIcon icon={faWhatsapp}/> WhatsApp
                                </label>
                                <br/>
                                <label>
                                    <Field type="checkbox" name="sms_comms" className={`rounded mx-3 p-4 border text-xs mt-2 focus:outline-0
                                        bg-white text-gray-900 placeholder:text-gray-100 ${errors?.sms_comms ? 'border-[#FF0002]' : ''}`}/>
                                    Send to <FontAwesomeIcon icon={faSms}/> SMS
                                </label>
                                <Field
                                    type="tel"
                                    name="mobile"
                                    placeholder="Phone number"
                                    required
                                    autoComplete={"tel"}
                                    className={`rounded w-full p-4 border text-xs mt-2
                                        bg-white text-gray-900 placeholder:text-gray-100 focus:outline-0 ${errors?.mobile ? 'border-[#FF0002]' : ''}`}
                                />
                                <ErrorMessage name="mobile">
                                    {(msg) => (
                                        <span className="text-sm text-[#FF0002]">
                                                {msg}
                                            </span>
                                    )}
                                </ErrorMessage>

                                <Field
                                    type="email"
                                    name="email"
                                    placeholder="Email address"
                                    required
                                    autoComplete={"email"}
                                    className={`rounded w-full p-4 border text-xs mt-2 focus:outline-0
                                        bg-white text-gray-900 placeholder:text-gray-100 ${errors?.email ? 'border-[#FF0002]' : ''}`}
                                />
                                <ErrorMessage name="email">
                                    {(msg) => (
                                        <span className="text-sm text-[#FF0002]">
                                                {msg}
                                            </span>
                                    )}
                                </ErrorMessage>
                            </div>
                            {/*<div className={`mt-8`}>*/}
                            {/*    <p className="text-base">Password</p>*/}
                            {/*    <Field*/}
                            {/*        type="password"*/}
                            {/*        name="password"*/}
                            {/*        required*/}
                            {/*        className={`rounded w-full p-4 border text-xs mt-2 focus:outline-0*/}
                            {/*        bg-[#00c1c6] placeholder:text-gray-100 ${errors?.password ? 'border-[#FF0002]' : ''}`}*/}
                            {/*    />*/}
                            {/*    <ErrorMessage name="password">*/}
                            {/*        {(msg) => (*/}
                            {/*            <span className="text-sm text-[#FF0002]">*/}
                            {/*                {msg}*/}
                            {/*            </span>*/}
                            {/*        )}*/}
                            {/*    </ErrorMessage>*/}
                            {/*</div>*/}

                            {/*<div className={`mt-8`}>*/}
                            {/*    <p className="text-base">Gender</p>*/}
                            {/*    <div className="flex justify-between mt-2">*/}
                            {/*        <div*/}
                            {/*            className={`w-[156px] h-[55px] text-xs flex justify-center items-center cursor-pointer rounded border-2 */}
                            {/*            bg-[#eeeeee] text-[#2e8889] hover:border-[#ec6730] ${*/}
                            {/*                values.sex === "M" ? "border-[#ec6730]" : "border-[#a6a6a6]"*/}
                            {/*            }`}*/}
                            {/*            onClick={() => {*/}
                            {/*                setFieldValue('sex', 'M')*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            Male*/}
                            {/*        </div>*/}
                            {/*        <div*/}
                            {/*            className={`w-[156px] h-[55px] text-xs flex justify-center items-center cursor-pointer rounded border-2 */}
                            {/*            bg-[#eeeeee] text-[#2e8889] hover:border-[#ec6730] ${*/}
                            {/*                values.sex === "F" ? "border-[#ec6730]" : "border-[#a6a6a6]"*/}
                            {/*            }`}*/}
                            {/*            onClick={() => {*/}
                            {/*                setFieldValue('sex', 'F')*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            Female*/}
                            {/*        </div>*/}
                            {/*        <div*/}
                            {/*            className={`w-[156px] h-[55px] text-xs flex justify-center items-center cursor-pointer rounded border-2 */}
                            {/*            bg-[#eeeeee] text-[#2e8889] hover:border-[#ec6730] ${*/}
                            {/*                values.sex === "Mx" ? "border-[#ec6730]" : "border-[#a6a6a6]"*/}
                            {/*            }`}*/}
                            {/*            onClick={() => {*/}
                            {/*                setFieldValue('sex', 'Mx')*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            Other*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className={`mt-8`}>*/}
                            {/*    <p className="text-base">Weight (optional)</p>*/}
                            {/*    <p className="text-sm">*/}
                            {/*        This help us recommend you the right weights and give your more tailored progress recommendations*/}
                            {/*    </p>*/}

                            {/*    <div className="flex mt-2 gap-2">*/}
                            {/*        <div*/}
                            {/*            className={`w-[55px] h-[30px] text-xs flex  justify-center items-center cursor-pointer rounded border-2 */}
                            {/*            bg-[#eeeeee] text-[#2e8889] hover:border-[#ec6730] ${*/}
                            {/*                values.unit_preference == "kg" ? "border-[#ec6730]" : "border-[#a6a6a6]"*/}
                            {/*            }`}*/}
                            {/*            onClick={() => {*/}
                            {/*                setFieldValue('unit_preference', 'kg')*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            kg*/}
                            {/*        </div>*/}
                            {/*        <div*/}
                            {/*            className={`w-[55px] h-[30px] text-xs flex  justify-center items-center cursor-pointer rounded border-2 */}
                            {/*            bg-[#eeeeee] text-[#2e8889] hover:border-[#ec6730] ${*/}
                            {/*                values.unit_preference == "pounds" ? "border-[#ec6730]" : "border-[#a6a6a6]"*/}
                            {/*            }`}*/}
                            {/*            onClick={() => {*/}
                            {/*                setFieldValue('unit_preference', 'pounds')*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            Pounds*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*    <Field*/}
                            {/*        type="number"*/}
                            {/*        name="body_weight"*/}
                            {/*        placeholder={values.unit_preference ? values.unit_preference === 'kg' ? "Kilograms" : "Pounds" : null}*/}
                            {/*        className={`rounded w-full p-4 border text-xs mt-2 bg-[#00c1c6] placeholder:text-gray-100 */}
                            {/*            focus:outline-0 ${errors?.body_weight ? 'border-[#FF0002]' : ''}`}*/}
                            {/*    />*/}
                            {/*    <ErrorMessage name="body_weight">*/}
                            {/*        {(msg) => (*/}
                            {/*            <span className="text-sm text-[#FF0002]">*/}
                            {/*                {msg}*/}
                            {/*            </span>*/}
                            {/*        )}*/}
                            {/*    </ErrorMessage>*/}
                            {/*</div>*/}

                            {/*<div className={`mt-8`}>*/}
                            {/*    <p className="text-base">Weight training experience (optional)</p>*/}
                            {/*    <div className="flex justify-between mt-2">*/}
                            {/*        <div*/}
                            {/*            className={`w-[156px] h-[55px] text-xs flex  justify-center items-center cursor-pointer rounded border-2 */}
                            {/*             bg-[#eeeeee] text-[#2e8889] hover:border-[#ec6730] ${*/}
                            {/*                values.lifting_experience_id === 1*/}
                            {/*                    ? "border-[#ec6730]" : "border-[#a6a6a6]"*/}
                            {/*            } `}*/}
                            {/*            onClick={() => {*/}
                            {/*                setFieldValue("lifting_experience_id", 1)*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            Beginner*/}
                            {/*        </div>*/}
                            {/*        <div*/}
                            {/*            className={`w-[156px] h-[55px] text-xs flex  justify-center items-center cursor-pointer rounded border-2 */}
                            {/*            bg-[#eeeeee] text-[#2e8889] hover:border-[#ec6730] ${*/}
                            {/*                values.lifting_experience_id === 2*/}
                            {/*                    ? "border-[#ec6730]" : "border-[#a6a6a6]"*/}
                            {/*            }`}*/}
                            {/*            onClick={() => {*/}
                            {/*                setFieldValue("lifting_experience_id", 2)*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            Intermediate*/}
                            {/*        </div>*/}
                            {/*        <div*/}
                            {/*            className={`w-[156px] h-[55px] text-xs flex  justify-center items-center cursor-pointer rounded border-2 */}
                            {/*            bg-[#eeeeee] text-[#2e8889] hover:border-[#ec6730] ${*/}
                            {/*                values.lifting_experience_id === 3*/}
                            {/*                    ? "border-[#ec6730]" : "border-[#a6a6a6]"*/}
                            {/*            }`}*/}
                            {/*            onClick={() => {*/}
                            {/*                setFieldValue("lifting_experience_id", 3)*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            Advanced*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*{*/}
                            {/*    ptSessionSelected && (*/}
                            {/*        <div className={`mt-8`}>*/}
                            {/*            <p className="text-base font-bold text-white">Booking summary</p>*/}
                            {/*            <div className="mt-3 flex justify-between">*/}
                            {/*                <p className="text-sm">*/}
                            {/*                    {moment(ptSessionSelected.date).utc().format("dddd")},*/}
                            {/*                    {` ${moment(ptSessionSelected.date).utc().format("Do MMMM h:mma")}`}*/}
                            {/*                </p>*/}
                            {/*                <p className="text-xs">£0.00</p>*/}
                            {/*            </div>*/}
                            {/*            <p className="text-xs">*/}
                            {/*                {ptSessionSelected.creator?.name}*/}
                            {/*            </p>*/}
                            {/*        </div>*/}
                            {/*    )*/}
                            {/*}*/}

                            {/*<div className="border-t mt-4"/>*/}

                            {/*<div className="mt-3 flex justify-between">*/}
                            {/*    <p className="text-base font-bold">Total</p>*/}
                            {/*    <p className="text-base font-bold">£0.00</p>*/}
                            {/*</div>*/}

                            <button
                                disabled={isSubmitting}
                                className={`mt-8 w-full text-center text-white py-2 rounded 
                                     bg-[#ec6730] cursor-pointer font-bold
                                     disabled:bg-gray-300 disabled:cursor-none`}
                                type="submit"
                            >
                                {
                                    isSubmitting ? (<FaSpinner className={`animate-spin w-5 h-5 m-auto`}/>) : 'Confirm'
                                }
                            </button>

                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}

export default Person;
