import {IUser} from "@/types/type.ts";
import {RequestOtp} from "@/components/request-otp.tsx";
import {OtpForm} from "@/components/otp-form.tsx";
import {Testimonial, TestimonialProps} from "@/components/testimonial.tsx";
import {useState} from "react";
import Carousel from "@/components/carousel/Carousel.tsx";

interface UsernameProps {
    nextStep: (username: string, response?: IUser) => void
}

export const Username = ({nextStep}: UsernameProps) => {
    const [username, setUsername] = useState("");
    const [showOTP, setShowOTP] = useState(false);

    const testimonials: TestimonialProps[] = [
        {
            url: "https://26655932.fs1.hubspotusercontent-eu1.net/hubfs/26655932/Abi1.png",
            name: "Abi",
            quote: "The best thing I've ever done",
            text: "I'd always wanted to get stronger and learn to lift weights, but never had the confidence to do it myself. I tried group coaching with a couple of friends and it's genuinely one of the best things I've ever done. I saw results within just a few weeks and it's given me so much more self-belief."
        },
        {
            url: "https://26655932.fs1.hubspotusercontent-eu1.net/hubfs/26655932/Aaron.png",
            name: "Aaron",
            quote: "It pushed me to new levels",
            text: "I've loved small group PT so — it lets me see how far I can push myself.  It's really taken me to levels I didn't really know I had in me."
        },
        {
            url: "https://26655932.fs1.hubspotusercontent-eu1.net/hubfs/26655932/Natasha.png",
            name: "Natasha",
            quote: "I love the results I'm seeing",
            text: "I'd being going to the gym for a few years but wasn't really seeing much progress. But then I found small group training and it genuinely transformed my experience at the gym — I'm loving the results I'm seeing. I'm way more confident and feel like I actually know what I'm doing now."
        }
    ]

    const slides = testimonials.map(testimonial => <Testimonial {...testimonial} className={"flex"}/>);
    const handleUserExists = (username: string) => {
        setUsername(username);
        setShowOTP(true);
    }
    const component = showOTP ? <OtpForm username={username} success={nextStep} cancel={() => setShowOTP(false)}/> :
        <RequestOtp userExists={handleUserExists} newUser={username => nextStep(username)}/>;
    return <>
        <div style={{backgroundImage: 'url("/hero.svg")', backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "top center"}}
             className={"lg:px-12 px-5 pt-6 md:mx-auto w-screen -mt-1"}>
            <div className="lg:flex lg:max-w-[1440px] mx-auto px-5 lg:px-12">
                <div className="lg:w-2/3">
                    <h1 className={'lg:text-7xl text-4xl font-black lg:mb-8 mb-7 font-family-din-pro lg:leading-[68px] lg:mt-14'}>Get stronger. Grow your confidence. See results.</h1>
                </div>
                <div className="lg:w-1/3"></div>
            </div>
            <div className={'flex md:h-[34rem] lg:max-w-[1440px] mx-auto px-5 lg:px-12 flex-col-reverse md:flex-row'}>
                <div className={"md:w-1/2 lg:w-2/3 w-full"}>
                    <h3 className={'lg:text-5xl text-2xl lg:mb-8 mb-7 mb:leading-[54px] font-family-abel-pro'}>Try Brawn’s proven private group 8-week coaching programme</h3>
                    <div className={'flex-1 font-family-abel-pro'}>
                        <div className={'lg:mb-8 mb-7'}>
                            {component}
                        </div>
                        <div>
                            <h4 className={'lg:text-3xl text-2xl lg:mb-8 mb-7 lg:leading-10'}>Sign up today with our special January offer: get your first two sessions for just £5!
                            </h4>
                            <ul className={'list-disc mb:leading-[54px] mb:text-[24px] lg:mb-8 mb-7 text-[18px] leading-7 ml-4'}>
                                <li>Follow a proven, science-backed plan that gets results</li>
                                <li>Be supported by, and make progress alongside your private group of 4-6 people</li>
                                <li>The most budget-friendly personal training option</li>
                                <li>Perfect for beginners or intermediates</li>
                            </ul>
                            <p className={'text-[16px]'}>Pause or cancel anytime. You’ll be booked onto a recurring weekly subscription after your first two weeks.</p>
                        </div>
                    </div>
                </div>
                <div className={'p-3 md:w-1/2 lg:w-1/3 h-full m-auto w-full'}>
                    <video autoPlay loop muted playsInline className={'justify-items-center items-center'} style={{objectFit: "contain", height: "100%", width: "100%"}}>
                        <source src="https://26655932.fs1.hubspotusercontent-eu1.net/hubfs/26655932/members%20only%20promo-1.mp4" type="video/mp4"/>
                    </video>
                </div>
            </div>
        </div>

        <div className={"lg:px-12 px-5 pt-6 lg:max-w-[1440px] md:mx-auto"}>
            <div className={"border-t border-gray-300 lg:w-1/3"}></div>
            <div className={'py-12'}>
                <h2 className={"lg:text-3xl text-2xl font-bold max-w-[600px] font-family-din-pro lg:mb-8 mb-7"}>Thousands of others have experienced the benefits of small group
                    PT</h2>
                <div className={'theme-light'}>
                    <Carousel slides={slides} className={""}></Carousel>
                </div>
            </div>
        </div>
    </>
}
