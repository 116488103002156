import {FaSpinner} from "react-icons/fa6";
import {IGroup} from "@/types/type.ts";
import {useEffect, useState} from "react";
import {searchGroup} from "@/apis/api.tsx";
import {Header} from "@/components/commons/Header.tsx";
import * as Sentry from "@sentry/react";

interface GymPickerProps {
    nextStep: (group: IGroup) => void,
    homeGym: string
}

export const GymPicker = ({nextStep, homeGym}: GymPickerProps) => {
    const [groups, setGroups] = useState<IGroup[]>([]);
    const [loading, setLoading] = useState(true);
    const [groupSelected, setGroupSelected] = useState<IGroup | null>(null);

    useEffect(() => {
        if (groups.length === 0) {
            setLoading(true)
            searchGroup()
                .then(data => {
                    const find = data.find(element => element.name === homeGym);
                    if (find) {
                        setGroupSelected(find);
                    }
                    setGroups(data);
                })
                .catch((error) => {
                    Sentry.captureException(error);
                    console.error(error);
                })
                .finally(() => setLoading(false));
        }
    }, [groups, homeGym])

    return <>
        <Header title={`Book small group training sessions`} subtitle={`Select your home gym`}/>
        <div className={'flex-1'}>
            <p className={`text-xs`}>
                If you’re not yet a member of your closest PureGym, you’ll be given a free day pass
            </p>

            <div className={`py-5`}>
                {
                    loading ? (
                        <div className={`text-center w-full`}>
                            <FaSpinner className="animate-spin m-auto w-8 h-8"/>
                        </div>
                    ) : (
                        <select className={`w-full p-2 border rounded-lg bg-white text-gray-900 focus:outline-0 text-base`} value={groupSelected?.id}
                                onChange={(event) => {
                                    const find = groups.find(element => element.id === +event.target.value);
                                    if (find) {
                                        setGroupSelected(find);
                                    }
                                }}>
                            <option value={0}>Select your home gym</option>
                            {groups.map((item, key) => <option key={key} value={item.id}>{item.display_name}</option>)}
                        </select>
                    )
                }
            </div>
            <button
                disabled={groupSelected === null}
                className={`mt-8 w-full text-center text-white py-2 rounded-lg
                                     bg-[#ec6730] cursor-pointer font-bold
                                     disabled:bg-gray-300 disabled:cursor-none`}
                type="button"
                onClick={() => {
                    if (groupSelected === null) {
                        console.log('Please select a gym');
                        return;
                    }
                    nextStep(groupSelected);
                }}
            >Confirm home gym
            </button>
        </div>

    </>
}
