import {ReactNode, useEffect, useMemo, useState} from "react";
import CheckCircle from "@/assets/images/CheckCirclePGBlue.svg";
import WeeklyIcon from "@/assets/images/dumbbell-icon.svg";
import TrainingBlockIcon from "@/assets/images/training-block-icon.svg";
import {LiaTimesSolid} from "react-icons/lia";

interface ProductSelectorProps {
    nextStep: (isBlockPurchase: boolean) => void,
    isBlockPurchase: boolean | null
}

export const ProductSelector = ({nextStep, isBlockPurchase}: ProductSelectorProps) => {

    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

    const products = useMemo(() => [
        {
            id: 1,
            name: "8-week training programme",
            isBlock: true,
            price: 50,
            priceSubTitle: "£8.33 per week (equivalent)",
            icon: TrainingBlockIcon,
            label: "Best Value",
            bullets: [
                "One in-person session/week",
                "Get stronger",
                "Build confidence",
                "Feel great"
            ],
            smallPrint: [
                "Get your first two sessions for £5. The next six sessions will cost a total of £50.",
                "Upon completion of the 8-week programme, you will be automatically enrolled into recurring weekly sessions at £10.99 per week."
            ],
            className: "border-t-amber-500 border-t-8 md:w-2/3 md:mr-3 ",
            unselectedClassName: "px-[20px] pb-[28px]",
            selectedClassName: "border-amber-500 border-8"
        },
        {
            id: 2,
            name: "Weekly Sessions",
            isBlock: false,
            price: 10.99,
            priceSubTitle: "per week",
            icon: WeeklyIcon,
            bullets: [
                "One in-person session/week",
                "Build training habits",
                "Get stronger",
                "Build confidence"
            ],
            smallPrint: [
                "Book sessions each week to fit around your schedule. Each session costs £10.99."
            ],
            className: "border-t-cyan-400 border-t-8 md:ml-3",
            unselectedClassName: "px-[20px] pb-[28px]",
            selectedClassName: "border-cyan-400 border-8"
        }
    ], []);
    useEffect(() => {
        if (isBlockPurchase === true) {
            setSelectedProduct(products[0]);
        } else if (isBlockPurchase === false) {
            setSelectedProduct(products[1]);
        }
    }, [isBlockPurchase, products]);

    interface BlockProps {
        children: ReactNode,
        className?: string
    }

    const Block = ({children, className = ""}: BlockProps) =>
        <div className={`flex min-h-full bg-white px-3 py-5 rounded text-black flex-col ${className} mb-12`}>
            {children}
        </div>;

    interface BlockHeaderProps {
        name: string,
        price: number,
        priceSubTitle: string,
        label?: string,
        icon: string
    }

    const BlockHeader = ({name, price, priceSubTitle, label, icon}: BlockHeaderProps) => <div>
        <div className={'flex content-between mb-2'}>
            <div className={"flex-grow"}>
                <img src={icon} alt={"Training block icon flex-grow-0"} className={'w-12 h-12'}/>
            </div>
            {label &&
                <div className={'flex-grow-0'}>
                    <div className={'bg-[rgba(236, 103, 48, 0.1)] rounded py-0.5 px-1.5 text-[#EC6730] text-sm flex-grow-0'}>{label}</div>
                </div>
            }
        </div>
        <h3 className={'font-bold text-lg'}>{name}</h3>
        <h2 className={'font-bold text-3xl my-2'}>&pound;{price}&nbsp;<span className={'font-bold text-lg text-gray-500'}>{priceSubTitle}</span></h2>
    </div>

    const BlockBody = ({bullets, smallPrint}: { bullets: string[], smallPrint: string[] }) => {
        return <div className={'flex-grow'}>
            <ul className={''}>
                {bullets.map((bullet, index) => <BulletPoint key={index}>{bullet}</BulletPoint>)}
            </ul>
            {smallPrint.map((text, index) => <p key={index} className={'mb-2'}><small>{text}</small></p>)}
        </div>
    }

    interface BlockFooterProps {
        onClick: () => void,
        selected: boolean
    }

    const BlockFooter = ({onClick, selected}: BlockFooterProps) => {
        return <div>
            <button className={"w-full mt-3 rounded-lg border-4 text-xl  font-bold p-1 " + (selected ? "text-white bg-[#00C1C6]" : "text-[#00C1C6]")}
                    style={{borderColor: "#00C1C6"}} onClick={onClick}>Select
            </button>
        </div>
    }

    interface BulletPointProps {
        children: ReactNode
    }

    const BulletPoint = ({children}: BulletPointProps) => <li>
        <p className={'pb-2'}><img src={CheckCircle} alt={""} className={'mr-2 inline-block'} style={{height: "24px", width: "24px"}}/>
            {children}
        </p>
    </li>

    interface Product {
        id: number,
        name: string,
        isBlock: boolean,
        price: number,
        priceSubTitle: string,
        icon: string,
        label?: string,
        bullets: string[],
        smallPrint: string[],
        className: string,
        selectedClassName?: string
        unselectedClassName: string;
    }


    return <>
        <div className={'text-white flex-grow lg:px-12 px-5'}>
            <div className={'text-center text-white md:mb-6 mb-14'}>
                <h3 className={'font-bold text-3xl mb-1'}>Choose your membership plan</h3>
                <h3 className={'text-xl text-gray-100 mb-12 md:mb-8'}>Select which best suits your personal goals</h3>
                <hr className={'my-3 md:w-2/3 lg:w-1/2 xl:w-1/3 mx-auto border-gray-200'}/>
                <h3 className={'text-xl font-bold mt-4 mb-2 text-gray-100'}>Introductory offer!</h3>
                <div className={"bg-amber-500 p-3 rounded w-80 md:w-96 mx-auto mb-6 relative shadow-2xl"}>
                    <div className={'ribbon text-white font-bold'}>Offer</div>
                    <h1 className={'font-bold text-5xl text-white'}>2 for £5</h1>
                </div>
                <p className={'text-white mb-6 font-medium'}>First 2 sessions for only £5. The plan you choose below will begin after these 2 weeks.</p>
                <hr className={'my-3 md:w-2/3 lg:w-1/2 xl:w-1/3 mx-auto border-gray-200'}/>
            </div>
            <div className={'md:flex items-stretch max-w-4xl mx-auto'}>
                {products.map((product, index) =>
                    <Block key={index}
                           className={product.className + " shadow-lg " + (product.id === selectedProduct?.id ? product.selectedClassName : product.unselectedClassName)}>
                        <BlockHeader name={product.name} price={product.price} priceSubTitle={product.priceSubTitle} icon={product.icon} label={product.label}/>
                        <BlockBody bullets={product.bullets} smallPrint={product.smallPrint}/>
                        <BlockFooter onClick={() => setSelectedProduct(product)} selected={product.id === selectedProduct?.id}/>
                    </Block>)}
            </div>
        </div>
        {!!selectedProduct &&
            <div className={`sticky bottom-0 left-0 bg-gray-100
                        md:pt-2 pt-6 md:pb-5 pb-24 md:w-[529px] px-5 
                        border-t-8
                        md:mx-auto shadow-2xl ` + (selectedProduct.isBlock ? "border-t-amber-500" : "border-t-cyan-400")}>
                <p className={'font-bold text-gray-800 pt-2 flex-grow'}>You have selected:</p>
                <div className={`mt-3`}>
                    <div className={`flex justify-between items-center border-2 border-gray-300 text-gray-800  
                                    rounded-lg p-2`}>{selectedProduct.name}
                        <button onClick={() => setSelectedProduct(null)}>
                            <LiaTimesSolid className={`w-5 h-5 font-thin`}/>
                        </button>
                    </div>
                </div>

                <button className={`mt-5 w-full text-center text-white py-2 rounded-lg 
                                 bg-[#ec6730] cursor-pointer font-bold                                
                                 disabled:bg-black`}
                        onClick={() => nextStep(selectedProduct.isBlock)}
                >
                    Proceed to session selection
                </button>
            </div>
        }
    </>
}
