import {requestOTP} from "@/apis/api.tsx";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {FaSpinner} from "react-icons/fa6";
import * as Sentry from "@sentry/react";

interface RequestOtpProps {
    userExists: (username: string) => void,
    newUser: (username: string) => void
}

export const RequestOtp = ({userExists, newUser}: RequestOtpProps) => {
    return <Formik enableReinitialize={false} initialValues={{username: ""}} onSubmit={(values, {setSubmitting}) => {
        setSubmitting(true);
        requestOTP(values.username)
            .then((res) => {
                if (res.status === 201) {
                    userExists(values.username);
                } else {
                    newUser(values.username);
                }
                setSubmitting(false);
            })
            .catch((e) => {
                Sentry.captureException(e);
                console.log(e);
                newUser(values.username);
                setSubmitting(false);
            });
    }}>
        {({isSubmitting, errors}) => (<Form>
            <div>
                <p className={'font-bold text-lg'}>Enter your email to get started</p>
                <div className={'md:flex items-center'}>
                    <div className={'md:mr-2'}>
                        <Field
                            type="email"
                            name="username"
                            placeholder="johndoe@exampleemail.com"
                            required
                            autoComplete={"email"}
                            className={`rounded-lg p-4 border focus:outline-0 bg-white lg:w-80 h-12 font-sans w-full mb-2 md:mb-0 text-gray-900
                                        placeholder:text-gray-500 ${errors?.username ? 'border-[#FF0002]' : ''}`}
                        />
                        <ErrorMessage name="username">
                            {(msg) => (
                                <span className="text-sm text-[#FF0002]">
                                                {msg}
                                            </span>
                            )}
                        </ErrorMessage>
                    </div>
                    <button
                        disabled={isSubmitting}
                        className={`text-center text-white rounded-lg 
                                     bg-[#ec6730] cursor-pointer font-bold md:w-44 w-full h-12 font-sans
                                     disabled:bg-gray-300 disabled:cursor-none`}
                        type="submit"
                    >
                        {
                            isSubmitting ? (<FaSpinner className={`animate-spin w-5 h-5 m-auto`}/>) : 'Get started'
                        }
                    </button>
                </div>
            </div>
        </Form>)}
    </Formik>;
}
