import {ReactNode} from "react";

interface WrapperProps {
    children: ReactNode,
    className?: string
    bgImage?: boolean
}

export const Wrapper = ({children, className, bgImage}: WrapperProps) => {
    return <div className={`relative w-full text-white bg-[#54B4B8] ${className}`}
                // style={bgImage ? {backgroundImage: 'url("/hero.svg")', backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "top center"} : {}}
    >
        <div className={`flex flex-col justify-between min-h-screen mx-auto relative w-full ` + (bgImage ? "" : "lg:px-12 px-5 pt-6 md:max-w-[529px] lg:max-w-[1440px]")}>
            {children}
        </div>
    </div>;
}
